import React from "react";
import { useCallback, useEffect, useState, useRef } from "react";
// import "survey-core/modern.min.css";
import "survey-core/defaultV2.min.css";
import them from "../../theme/survey_theme.json";
import { Model, Serializer, SurveyModel, SvgRegistry } from "survey-core";
import { ReactQuestionFactory, Survey } from "survey-react-ui";
import { useParams, useSearchParams } from "react-router-dom";
import { SurveyTakerService } from "./SurveyTaker.service";
import { Button, Result, Spin } from "antd";
import queryString from "query-string";
import "./survey.scss";
import { v4 as uuidv4 } from "uuid";

//REGISTER CUSTOM QUESTIONS
import { SurveyLogic, localization } from "survey-creator-core";
import ReCAPTCHA from "react-google-recaptcha";
import { addScript, isScriptPresent, parseJwt } from "../../utils/utils";
import { IS_SET_FRAUD_RISK_SCORE, RECAPTCHA_SRC_URL, REV_ID_SRC_URL, RECAPTCHAV3_KEY } from "../../constants/env";
import { callRevIdService } from "../RevID/RevID.utils";
import { RevIdCallResponse } from "../RevID/RevID.types";
import { doMarkdown } from "../../survey-js-extensions/utils/dynamic-text.utils";
import { savePositionItem } from "../../survey-js-extensions/utils/survey.utils";
import { registerConstantSum } from "../../survey-js-extensions/widget/ConstantSum/ConstantSum.widget";
import { registerCounterQuestion } from "../../survey-js-extensions/widget/CounterQuestion";
import IncrementCounterTrigger from "../../survey-js-extensions/widget/IncrementCounterTrigger";
import * as customQuestions from "../../survey-js-extensions/custom-questions/index";
import { registerPageBreak } from "../../survey-js-extensions/widget/PageBreak/PageBreakQuestion";
import {
  ReCAPTCHA_TYPE,
  SurveyQuestionReCAPTCHA,
} from "../../survey-js-extensions/widget/ReCAPTCHA";
import {
  checkAndUpdateValues,
  disableNextPageButtonOrCompleted,
  enableNextPageButtonOrCompleted,
} from "./utils/survey.utils";
import SurveyFlowCtrl from "./surveyFlow";
import {
  onValueChangedHandler,
  registerCustomChoiceComponent,
  updateDependantQuestion,
} from "../../survey-js-extensions/extensions/extension-utils";
import { registerRadioGroupItemExtension } from "../../survey-js-extensions/extensions/radiogroup-item.extension";
import {
  onValueChangingCheckbox,
  onValueChangingMultiSelect,
  onValueChangingMultiSelectComment,
  onValueChangingSelect,
  registerCheckboxItemExtension,
  validateTextEntry
} from "../../survey-js-extensions/extensions/checkbox-item-extension";

import "../../survey-js-extensions/extensions/surveyjs-properties-extension";
import { modifySurveyJsonForPageBreaks } from "./utils/flow-engine.utils";
import { env } from "process";
import { surveyWithPageBreaksStore } from "../../store/pageBreak_store";
import { SurveyProps, SurveyResponse } from "./types/survey.types";
import { ExecutionOptions, PreviewOptions } from "../../survey-js-extensions/types/dynamic-text.types";
import { clearLocalStorageByPattern, getLastTokenFromSurveyStorage, getLocalStorageItem, isTokenExpired, localStorageKeys, removeLocalStorageItem, setLocalStorageItem } from "./types/constant";
import _, { isEmpty } from "lodash";
import { setNumberAttributeInInput } from "../../survey-js-extensions/utils/utils";
import { onAfterRenderQuestionHTML, onValueChangedHTML } from "../../survey-js-extensions/extensions/html-extension";
import { observer } from "mobx-react";
import { addIncrementQuotaToFlowEngine, addVariablesToFlowEngine, addVariablesToSurvey, countersToObject, insertBeforeFlowEngine, mapOverrideToSetIdentifier } from "../../survey-js-extensions/utils/variables.utils";
import { initCustomItemRankingImage } from "../../survey-js-extensions/widget/RankingImage/CustomItemRankingImage";
import { registerRankingImage } from "../../survey-js-extensions/widget/RankingImage/RankingImage.widget";
import { variablesStore } from "../../store/variables_store";
import { HVariable } from "../../survey-js-extensions/types/variables.types";
import { settings } from "survey-core";
import { upgradeToLatestVersion } from "../FlowEngine/flow-engine-version.utils";
import { addIdentifiers } from "../FlowEngine/flow-engine-crud.utils";
import { registerQuotaQuestion } from "../../survey-js-extensions/widget/QuotaQuestion";
import IncrementQuotaTrigger from "../../survey-js-extensions/widget/IncrementQuotaTrigger";

const dropdown = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_1825_705" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1825_705)">
  <path d="M11.9997 14.6615C11.8791 14.6615 11.767 14.6423 11.6631 14.6038C11.5593 14.5654 11.4606 14.4993 11.367 14.4057L6.87276 9.91152C6.73429 9.77307 6.66346 9.59904 6.66026 9.38942C6.65704 9.1798 6.72788 9.00256 6.87276 8.8577C7.01763 8.71283 7.19326 8.6404 7.39966 8.6404C7.60606 8.6404 7.78169 8.71283 7.92656 8.8577L11.9997 12.9308L16.0728 8.8577C16.2112 8.71925 16.3852 8.64841 16.5949 8.6452C16.8045 8.642 16.9817 8.71283 17.1266 8.8577C17.2714 9.00256 17.3439 9.1782 17.3439 9.38462C17.3439 9.59102 17.2714 9.76665 17.1266 9.91152L12.6323 14.4057C12.5388 14.4993 12.44 14.5654 12.3362 14.6038C12.2324 14.6423 12.1202 14.6615 11.9997 14.6615Z" fill="#6E6E6E"/>
</g>
</svg>`;

customQuestions.nouislider();
registerCounterQuestion();
registerQuotaQuestion();
registerConstantSum();
registerPageBreak();
registerRankingImage();
initCustomItemRankingImage()

Serializer.addClass(
  IncrementCounterTrigger.triggerName,
  ["targetCounter:questionvalue"],
  function () {
    return new IncrementCounterTrigger();
  },
  "surveytrigger"
);
if (!Serializer.findProperty("question", "showPopupIf")) {
  SurveyLogic.types.push({
    name: "increment_counter",
    baseClass: "incrementcountertrigger",
    propertyName: "expression",
  });
  const translations = localization.getLocale("");
  const locLogic = translations.ed.lg;
  locLogic["increment_counterName"] = "Increment Counter";
  locLogic["increment_counterText"] = "Make question count";
}


Serializer.addClass(
  IncrementQuotaTrigger.triggerName,
  ["targetQuota:quotaquestionvalue"],
  function () {
    return new IncrementQuotaTrigger();
  },
  "surveytrigger"
);
if (!Serializer.findProperty("question", "showPopupIf")) {
  SurveyLogic.types.push({
    name: "increment_quota",
    baseClass: "incrementquotatrigger",
    propertyName: "expression",
  });
  const translations = localization.getLocale("");
  const locLogic = translations.ed.lg;
  locLogic["increment_quotaName"] = "Increment Quota";
  locLogic["increment_quotaText"] = "Make question count";
}

Serializer.addProperty("itemvalue", {
  name: "rowIndex",
  type: "number",
  visible: false,
});
Serializer.getProperty("matrix", "rowsOrder").visible = false;
Serializer.getProperty("matrix", "rowsOrder").default = false;

Serializer.addProperty("matrix", {
  name: "customRowsOrder",
  displayName: "CUSTOM ROWS ORDER",
  choices: ["initial", "random"],
  category: "rows",
});

Serializer.addProperty("survey", {
  name: "showSectionTitles",
  type: "boolean",
  category: "general",
  default: true,
});
Serializer.findProperty('survey', 'showSectionTitles').displayName = 'Show section titles'
Serializer.addProperty("survey", {
  name: "showSectionDescription",
  type: "boolean",
  category: "general",
  default: true,
});
Serializer.findProperty('survey', 'showSectionDescription').displayName = 'Show section descriptions'
Serializer.addProperty("page", {
  name: "sectionTitleOption",
  type: "options",
  category: "general",
  choices: [ "inherit", "show", "hide" ],
  default: 'inherit',
});
Serializer.findProperty('page', 'sectionTitleOption').displayName = 'Section title'

Serializer.addProperty("page", {
  name: "sectionDescriptionOption",
  type: "options",
  category: "general",
  choices: [ "inherit", "show", "hide" ],
  default: 'inherit',
});
Serializer.findProperty('page', 'sectionDescriptionOption').displayName = 'Section description'

Serializer.addProperty("itemvalue", {
  name: "imageUrl",
});



SvgRegistry.registerIconFromSvg("chevron", dropdown);
ReactQuestionFactory.Instance.registerQuestion(ReCAPTCHA_TYPE, (props) => {
  return React.createElement(SurveyQuestionReCAPTCHA, props);
});
const TakeSurvey = () => {
  const { orgId, token } = useParams();
  const [searchParams] = useSearchParams();

  const fraudRevIdDupeThreashold = 75;
  const [loading, setLoading] = useState(false);
  const [counters, setCounters] = useState<any>([]);
  const surveyTakerService = new SurveyTakerService();
  const [surveyJson, setSurveyJson] = useState<SurveyModel>({} as SurveyModel);
  const [surveyPageBreaks, setSurveyPageBreaks] = useState<any>({});
  const [surveyFlow, setSurveyFlow] = useState<any | null>(null);
  const [surveyResponse, setSurveyResponse] = useState<SurveyResponse>(
    {} as SurveyResponse
  );
  const [surveyProps, setSurveyProps] = useState<SurveyProps>({} as SurveyProps);
  const [prevResponse, setPreviousResponse] = useState(null);
  const [prevHVars, setPreviousHvars] = useState(null);
  const [hPid, setHPid] = useState(null);
  const [initData, setInitData] = useState({});
  const [isSurveyTaken, setIsSurveyTaken] = useState(true);
  const [errorMessage, setErrorMessage] = useState(
    "There are some problems with your operation. Click below to take the survey again?"
  );
  const [errorClose, setErrorClose] = useState(false);
  const queryParams = queryString.parse(window.location.search);

  const reRef = useRef<ReCAPTCHA | null>(null)
  const surveyDesign = async () => {
    localStorage.removeItem("navigateToUrl");
    setLoading(true);
    let response;
    try {
      const usrToken = searchParams.get('t');
      if (usrToken) {
        response = await surveyTakerService.getSurveyTokenForIndividualLinks(usrToken, {redirect: false,...queryParams});
      }

      if ((token && token !== "" && orgId && orgId !== "")) {
        response = await surveyTakerService.getSurveyToken(token, orgId, {redirect: false,...queryParams});
      }
      
      const { data }: any = response;
      if (data && data.jwt) {
        setLoading(false);
        const token = usrToken ? data.jwt : getLastTokenFromSurveyStorage(data.jwt);
        const { survey } = parseJwt(token);
        setLocalStorageItem(localStorageKeys.SURVEY_TOKEN, token, {takerId: survey.takerId});
        setLocalStorageItem(localStorageKeys.SURVEY_ID, survey.designId, {takerId: survey.takerId});
        fetchSurveyJson(token);
      } else {
        setLoading(false);
        setErrorClose(true);
      }
    } catch (error: any) {
      if( error?.response?.data?.message) {
        setErrorMessage(error?.response?.data?.message);
      }
      setLoading(false);
      setErrorClose(true);
      setIsSurveyTaken(false);
    }
  };

  const fetchSurveyJson = async (token: string | "") => {
    setIsSurveyTaken(true);
    setLoading(true);
    if (token) {
      const response = await surveyTakerService.fetchSurvey(token);
      let surveyPropsData;

      if (!response.data) {
        return;
      }

      setSurveyResponse(response.data);
      
      if (response.data.surveyToken?.token) {
        const token = response.data.surveyToken.token;
        surveyPropsData = parseJwt(token)?.survey;
        removeLocalStorageItem(localStorageKeys.USER_SURVEY_FLOW, { takerId: surveyPropsData.takerId });
        //sync server flowPosition with localStorage.
        if (!_.isEmpty(response.data.survey?.flowPosition)) {
          setLocalStorageItem(localStorageKeys.USER_SURVEY_FLOW, JSON.stringify(response.data.survey?.flowPosition), { takerId: surveyPropsData.takerId });
        }
        setLocalStorageItem(localStorageKeys.SURVEY_TOKEN, token, {takerId: surveyPropsData.takerId});
        // This change only for Recaptcha question type
        document.body.dataset.token = token; 
        document.body.dataset.surveyId = surveyPropsData.designId;
        document.body.dataset.takerId = surveyPropsData.takerId;
        setSurveyProps(surveyPropsData);

        if (response.data.survey?.contactData) {
          variablesStore.patchValues(response.data.survey?.contactData);
        }

        if (response.data.survey?.data) setPreviousResponse(response.data.survey?.data);
        if (response.data.survey?.h_data) setPreviousHvars(response.data.survey?.h_data);
        if (response.data.survey?.PannelId) {variablesStore.setValue(HVariable.PanelId, response.data.survey?.PannelId);}
      }

      if (response.data.survey) {
        response.data.survey.modaljson = addVariablesToSurvey(response.data.survey.modaljson, response.data.survey);

        let miniFlow;
        if (response.data.survey.miniFlow?.distributions?.length > 0) {
          miniFlow = [response.data.survey.miniFlow?.distributions[0].flow];
        }

        let flow = response.data.survey.flow;

        flow = insertBeforeFlowEngine(flow, miniFlow || []);
        flow = addVariablesToFlowEngine(flow, response.data.survey);
        flow = upgradeToLatestVersion(flow);
        flow = addIdentifiers(flow);
        flow = addIncrementQuotaToFlowEngine(flow, response.data.survey?.quotas || [], countersToObject(response.data.survey?.quotaCounters));

        if (response.data.survey.counters.length) {
          const convertedArray = response.data.survey.counters.map(
            (obj: any) => {
              const key = Object.keys(obj)[0];
              const value = obj[key];
              return value;
            }
          );
          const countMap = convertedArray.reduce((map: any, obj: any) => {
            map[obj.linkKey] = obj.count;
            return map;
          }, {});
          setCounters(Object.entries(countMap));
        }

        if (response.data.survey?.initData?.QueryData) {
          setInitData(response.data.survey.initData.QueryData);
        }

        renderSurvey(response.data.survey.modaljson);
        setSurveyFlow(flow);
        modifySurveyJsonForPageBreaks(response.data.survey.modaljson);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    let PanelistID = surveyResponse?.survey?.PannelId;

    if (PanelistID) {
      variablesStore.setValue(HVariable.PanelId, PanelistID);
    }

    if (!surveyProps?.useRelevantID) {
      return;
    }

    if (!PanelistID) {
      PanelistID = surveyResponse?.survey?.RevPannelId || "";
    }

    const ClientID = surveyResponse?.survey?.RelevantIDClientId;
    const SurveyID = surveyProps?.designId;

    // add google recaptcha v3 script
    if (!isScriptPresent('recaptchaScript')) {
      addScript(`${RECAPTCHA_SRC_URL}?render=${RECAPTCHAV3_KEY}`, 'recaptchaScript');

      const performReCaptchaAndSetData = async () => {
        const captchaValidationResponse = await executeRecaptcha();
        const captchaScore = captchaValidationResponse?.responseResponse?.score;
        variablesStore.setValue(HVariable.ReCaptchaV3, captchaScore);
      };

      performReCaptchaAndSetData()
    }

    if (ClientID && PanelistID && SurveyID && !isScriptPresent('revIdScript')) {
      addScript(REV_ID_SRC_URL, 'revIdScript');

      const requestStartDate: Date = new Date();

      callRevIdService({ClientID, PanelistID, SurveyID})
        .then((revIdResponse: RevIdCallResponse) => {
          setRevIdData(revIdResponse as any);
          variablesStore.setValue(HVariable.IdDuplicate, revIdResponse?.FraudRiskProbability >= fraudRevIdDupeThreashold || !revIdResponse?.IsNew ? 1 : 0);
          variablesStore.setValue(HVariable.FraudRisk, revIdResponse.FraudRiskProbability);
          variablesStore.setValue(HVariable.RevIdDuplicateScore, revIdResponse.Score);
        }).catch((error: any) => {
          setRevIdData({error: true} as any);
        })
        .finally(() => {
          setRevIdData({
            ...getRevIdData(),
            RequestStartDate: requestStartDate,
            RequestEndDate: new Date(),
            RequestDuration: Math.abs(new Date() as any - (requestStartDate as any))
          } as RevIdCallResponse);
        });
    }
  }, [surveyProps]);


  const setRevIdData = (value: RevIdCallResponse | undefined): void => {
    setLocalStorageItem(localStorageKeys.REV_DATA, JSON.stringify(value), {takerId: surveyProps.takerId});
  };

  const getRevIdData = (): RevIdCallResponse | undefined => {
    const data = getLocalStorageItem(localStorageKeys.REV_DATA, {takerId: surveyProps.takerId});
    if (data !== "undefined") {
      return JSON.parse(data as string);
    }
    return;
  };

  type AnyObject = { [key: string]: any };

  const removeCustomSuffix = (obj: AnyObject): AnyObject => {
    return Object.entries(obj).reduce((acc: AnyObject, [key, value]) => {
      const newKey = key.endsWith('-custom') ? key.slice(0, -7) : key;
      acc[newKey] = value;
      return acc;
    }, {});
  };

  const surveyComplete = useCallback(
    (sender: { data: any }) => {
      let formattedData = removeCustomSuffix(sender.data)
      saveSurveyResults(formattedData);
    },
    [counters, surveyProps]
  );

  const renderSurvey = (json: any) => {
    setSurveyJson(json);
  };

  const setSurveyPageBreak = (json: any) => {
    setSurveyPageBreaks(json);
  };

  const saveSurveyResults = async (json: any) => {
    const token = getLocalStorageItem(localStorageKeys.SURVEY_TOKEN, {takerId: surveyProps.takerId}) || "";
    const redirectUrl = getLocalStorageItem(localStorageKeys.FLOW_REDIRECT, {takerId: surveyProps.takerId});
    if (surveyFlowCtrl.allowDeleteRespondentData) {
      //Still need to redirect even if the data is deleted
      if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      return;
    }
    // need to make decision here.
    const body = prepareSurveyPayload(json, false);
    if (token && body.surveyResponse) {
      const response: any = await surveyTakerService.submitSurvey(token, body);
      

      // NOTE!!!!! - keep redirectUrl above clearLocalStorageByPattern as it clears all local storage
      clearLocalStorageByPattern(surveyProps.takerId);
      if (response.data.response.id) {
        const urlFromStorage = localStorage.getItem("navigateToUrl");
        const url = urlFromStorage !== "undefined" ? urlFromStorage : surveyJson?.navigateToUrl;
        setLoading(false);
        setIsSurveyTaken(true);
        setRevIdData(undefined);


        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      } else if (response.data.statusCode == 400) {
        setLoading(false);
        setIsSurveyTaken(false);
        setErrorMessage(
          `${response.data.message[0]}. Click below to take the survey again?`
        );
      }
    } else {
      setLoading(false);
      setIsSurveyTaken(false);
      setErrorMessage(
        "There are some problems with your operation. Click below to take the survey again?"
      );
    }
  };
  // const modified = modifySurveyJsonForPageBreaks(surveyJson);
  const survey = new Model(surveyJson);
  settings.showItemsInOrder = "column";
  
  if (prevResponse) survey.data = prevResponse;
  if (prevHVars) {
    const { h_pid, h_finalstatus, h_flags} = prevHVars;
    if (h_pid) {
      setHPid(h_pid);
      variablesStore.setValue(HVariable.PanelId, h_pid);
    }

    if (h_finalstatus) {variablesStore.setValue(HVariable.FinalStatus, h_finalstatus);}
    if (h_flags) {variablesStore.setValue(HVariable.Flags, h_flags);}
  }

  survey.applyTheme(them as any);
  registerRadioGroupItemExtension(survey);
  registerCheckboxItemExtension(survey);

  //Question number displayed based on question displayed on screen no matter which page occurs first. Number starts with 1.
  survey.lastQuestionIndex = 0;
  survey.isTriggerFirst = false;
  survey.onAfterRenderPage.add((sender:any, options:any)=>{
    const isSurveySectionTitleShow =  sender.hasOwnProperty('showSectionTitles') ? sender.showSectionTitles : true;
    const isSurveySectionDescriptionShow =  sender.hasOwnProperty('showSectionDescription') ? sender.showSectionDescription : true;
    const pageTitle = options.page.sectionTitleOption;
    const pageDesc = options.page.sectionDescriptionOption;
    if(pageTitle === 'hide' || (pageTitle === 'inherit' && !isSurveySectionTitleShow)) {
      options.page.title = '';
    }
    if(pageDesc === 'hide' || (pageDesc === 'inherit' && !isSurveySectionDescriptionShow)) {
      options.page.description = '';
    }
    setNumberAttributeInInput()

    options.page.questions.forEach((q: any) => {
      if(q.getPropertyValue('no') && !survey.isTriggerFirst) {
        survey.lastQuestionIndex++;
        q.setPropertyValue("no", `${survey.lastQuestionIndex}.`);
      }
    });
    survey.isTriggerFirst = true;
  });
  survey.onCurrentPageChanged.add(function(sender, options) {
    if(options.isPrevPage) {
      const nums = options.oldCurrentPage.questions.map(i => i.getPropertyValue('no')).filter(i => i).map(Number)
      const minNumber = Math.min(...nums);
      options.newCurrentPage.questions.reverse().forEach((q: any, index: number) => {
        if(q.getPropertyValue('no')) {
          if(minNumber < survey.lastQuestionIndex) {
            survey.lastQuestionIndex = minNumber;
          }
          let num = 1;
          survey.lastQuestionIndex = survey.lastQuestionIndex - num;
          q.setPropertyValue("no", `${survey.lastQuestionIndex}.`);
        }
      });
      options.newCurrentPage.questions.reverse()
    }
    if(options.isNextPage) {
      const nums = options.oldCurrentPage.questions.map(i => i.getPropertyValue('no')).filter(i => i).map(Number)
      const maxNumber = Math.max(...nums);
      options.newCurrentPage.questions.forEach((q: any, index: number) => {
        if(q.getPropertyValue('no')) {
          if(maxNumber > survey.lastQuestionIndex) {
            survey.lastQuestionIndex = maxNumber;
          }
          survey.lastQuestionIndex++;
          q.setPropertyValue("no", `${survey.lastQuestionIndex}.`);
        }
      });
    }
  });

  survey.onValidateQuestion.add((_: any, options: any) => validateTextEntry(_, options, survey));

  survey.onAfterRenderSurvey.add((_, option) => {
    !survey.logo && (survey.showTitle = false);
  });

  survey.onCompleting.add(() => {
    survey.runTriggers();
  });
  survey.onAfterRenderQuestion.add((sender: any, options: any) => {
    onAfterRenderQuestionHTML(sender, options, PreviewOptions.Taker);
    registerCustomChoiceComponent(options);
    updateDependantQuestion(sender, options);
  });

  survey.onValueChanged.add((sender: any, options: any) => {
    onValueChangedHandler(sender, options);
    onValueChangedHTML(sender, options, PreviewOptions.Preview);
  });

  survey.onValueChanging.add((sender: any, options: any) => {
    onValueChangingCheckbox(sender, options);
    onValueChangingMultiSelect(sender, options);
    onValueChangingSelect(sender, options);
    onValueChangingMultiSelectComment(sender, options);
  });

  savePositionItem(survey);

  survey.onValueChanged.add((result, options) => {
    validateNextButton();
  });

  const validateNextButton = () => {
    setTimeout(() => {
      if (surveyJson.disableNextButtonByDefault) {
        survey.currentPage.validate(false, false)
          ? enableNextPageButtonOrCompleted()
          : disableNextPageButtonOrCompleted();
      }
    }, 0);
  };
  
  const executeRecaptcha = async () => {
    try {
      const captchaResponse = await reRef.current?.executeAsync();
      const token = getLocalStorageItem(localStorageKeys.SURVEY_TOKEN, {takerId: surveyProps.takerId});
      const surveyId = getLocalStorageItem(localStorageKeys.SURVEY_ID, {takerId: surveyProps.takerId});

      const res = await surveyTakerService.validationTokenCaptcha(token || "", {
        projectID: surveyId,
        captchaResponse: captchaResponse,
        invisible: true,
      });
  
      if (res.data && reRef) {
        reRef.current?.reset();
      }
  
      return res.data;
  
    } catch (error) {
      console.error('Error during captcha execution:', error);
      return { error: 'Failed to execute captcha.' };
    }
  };

  const prepareSurveyPayload = (json: any = {}, isPartial: boolean) => {
    const { innerWidth, innerHeight } = window;
    checkAndUpdateValues(json, counters);
    const userflowPosition = getLocalStorageItem(localStorageKeys.USER_SURVEY_FLOW, {takerId: surveyProps.takerId});
    return {
      ...(isPartial ? { flowPosition: userflowPosition ? JSON.parse(userflowPosition) : {} } : {}),
      surveyResponse: {
        ...json,
        ...(surveyProps?.useRelevantID && { h_revdata: getRevIdData() }),
        ...(surveyProps?.useRelevantID && { h_data: variablesStore.getValuesForPayload()}),
      },
      isPartial: isPartial,
      pageIndex: surveyFlowCtrl.noOfPagesRender,
      screenRes: `${innerWidth}x${innerHeight}`,
    };
  };

  survey.onAfterRenderPage.add((survey, options) => {
    validateNextButton();
  });

  survey.processText = (text: string, returnDisplayValue: boolean): string => {
    return text;
  };

  survey.onTextMarkdown.add((sender: any, options: any) => {
    doMarkdown(survey, options, {
      executionOption: ExecutionOptions.Display,
      previewOption: PreviewOptions.Taker
    });
  });

  survey.onValueChanged.add((sender: any, options: any) => {
    const htmlQuestions = survey.getAllQuestions().filter((model: any) => model.getType() === 'html');
    htmlQuestions.forEach((question: any) => {
      question.text = question.text || question.html;
      doMarkdown(survey, { element: question, text: question.text, name: question.name, html: question.html}, {
        executionOption: ExecutionOptions.Display,
        previewOption: PreviewOptions.Preview
      });
    });
  });

  survey.clearInvisibleValues = "none";

  //initialize data if available.
  Object.keys(initData).forEach((key) => {
    const data: any = initData;
    survey.setValue(key, data[key]);
  });

  counters.forEach((el: string[]) => {
    let value = parseInt(el[1]) || 0;
    let questionValue = survey.getValue(`${el[0]}`);
    if (questionValue >= value) {
        value = questionValue === value ? value : questionValue;
    }
    survey.setValue(`${el[0]}`, value);
  });

  survey.onComplete.add(surveyComplete);

  survey.onNavigateToUrl.add((SurveyModel, NavigateToUrlEvent) => {
    NavigateToUrlEvent.allow = false;
    localStorage.setItem("navigateToUrl", NavigateToUrlEvent.url);
  });

  const saveResults =  async (json: any) => {
    disableNextPageButtonOrCompleted();
    const token = getLocalStorageItem(localStorageKeys.SURVEY_TOKEN, {takerId: surveyProps.takerId}) || "";
    const { data } = await surveyTakerService.submitSurvey(token,prepareSurveyPayload(json, true));
    if (data?.surveyToken) setLocalStorageItem(localStorageKeys.SURVEY_TOKEN, data.surveyToken.token, {takerId: surveyProps.takerId});
    enableNextPageButtonOrCompleted();
  };

  const surveyFlowCtrl = new SurveyFlowCtrl(survey, surveyFlow, surveyProps, saveResults);

  // start the flow control
  if(surveyFlow && surveyProps) {
    surveyFlowCtrl.start(); 
  }
  
  const startSurvey = async (e: any) => {
    const token = getLocalStorageItem(localStorageKeys.SURVEY_TOKEN, {takerId: surveyProps.takerId}) || "";
    fetchSurveyJson(token);
  };

  useEffect(() => {
    surveyDesign();
  }, []);

  const indexOfComplete = survey.navigationBar.actions.findIndex(
    (item) => item.id === "sv-nav-complete"
  );
  if (indexOfComplete !== -1) {
    survey.navigationBar.actions[indexOfComplete].innerCss =
      "sd-btn sd-navigation__next-btn";
  }

  return (
    <>
      {errorClose ? (
        <Result status="warning" title={ errorMessage || "This survey is closed"} />
      ) : (
        <>
          {isSurveyTaken && (
            <Spin spinning={loading} size="large">
              <Survey model={survey} />
              <ReCAPTCHA
                sitekey={RECAPTCHAV3_KEY}
                size={"invisible"}
                ref={reRef}
              />
            </Spin>
          )}
          {!isSurveyTaken && (
            <Result
              status="warning"
              title={errorMessage}
              extra={
                <Button
                  type="primary"
                  key="console"
                  onClick={(e: any) => startSurvey(e)}
                >
                  Start Survey
                </Button>
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default TakeSurvey;
